import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const login = async (credentials) => {
    try {

        // Get the CSRF cookie from Sanctum
        await axios.get(BACKEND_URL + 'sanctum/csrf-cookie');
        // Make login request
        const response = await axios.post(API_URL + 'login', credentials);

        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('Login failed');
    }
};