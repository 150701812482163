import React from "react";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { ButtonBase, useTheme } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: "inherit",
    borderRadius: theme.shape.borderRadius,
    transition: "background-color 0.3s, color 0.3s",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
    },
    "&:active": {
      backgroundColor: theme.palette.action.selected, // Simulate button pressed effect
    },
  },
  active: {
    backgroundColor: theme.palette.action.selected, // Active background color
    color: theme.palette.primary.main, // Active text color
  },
  icon: {
    // color: theme.palette.primary.main,
    transition: "color 0.3s",
  },
  text: {
    // color: theme.palette.primary.main,
    fontSize: 13,
    transition: "color 0.3s",
  },
}));

const CustomIconButton = ({ icon: Icon, text, url, isActive }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <ButtonBase
      component={Link}
      to={url}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        color: isActive
        ? theme.palette.primary.main
        : theme.palette.text.secondary,
        padding: 2,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.primary.main,
        },
      }}
    >
      <Icon className={classes.icon} />
      <span className={classes.text}>{text}</span>
    </ButtonBase>
  );
};

export default CustomIconButton;
