import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const MENU_GROUP_URL = API_URL + "public/orders";

const createOrders = (reservation_id, cart) => {
    return axios.get(MENU_GROUP_URL + "/createOrder", {
        data: {
            reservation_id,
            cart
        }
    })
        .then(res => res.data)
        .catch(err => console.log(err));
}



export { createOrders }