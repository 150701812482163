import React from "react";
import { Box, Typography } from "@mui/material";
import MenuGroup from "./Partials/MenuGroup";
import TakeAwayDrawer from "./Partials/TakeAwayDrawer";
import { CartProvider } from "../../context/CartContext";

const TakeAwayPage = () => {
  return (
    <CartProvider>
      <Box sx={{ p: 4 }}>
        {/* Section Title */}
        <Typography variant="h4" component="p">
          Món ăn
        </Typography>
        <MenuGroup />
        <TakeAwayDrawer />
        {/* <PaymentDrawer /> */}
      </Box>
    </CartProvider>
  );
};

export default TakeAwayPage;
