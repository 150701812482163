import { Box, Typography } from "@mui/material";

const NoDataBox = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 155px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Typography variant="body1" color="textSecondary">
        Chưa có dữ liệu
      </Typography>
    </Box>
  );
};

export default NoDataBox;