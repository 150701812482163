import React from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Card,
  Grid2,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Stack,
  IconButton,
} from "@mui/material";
import { getMenuGroups } from "../../../requests/menugroups";
import { genImageUrl } from "../../../helpers/url";
import AddIcon from "@mui/icons-material/Add";
import { formatCurrency } from "../../../helpers/format";
import { useCart } from "../../../context/CartContext";
import Loading from "../../../components/Loading";

// TabPanel component to display content based on selected tab

const MenuGroup = () => {
  const [value, setValue] = React.useState(0);
  const [menuGroup, setMenuGroup] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const menuItemCategories = React.useMemo(() => menuGroup, [menuGroup]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    // call get menu item categories requests
    getMenuGroups().then((res) => {
      setMenuGroup(res);
    });
  }, []);

  const { addToCart } = useCart();

  if (loading) {
    return <Loading />;
  }

  return (
    <Box>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ justifyContent: "center" }}
        >
          {menuItemCategories?.map((tab, index) => (
            <Tab
              key={index}
              label={
                <Box textAlign="center">
                  <img
                    src={
                      tab.image_url
                        ? genImageUrl(tab.image_url)
                        : "/assets/imgs/blank-image.svg"
                    }
                    alt={tab.name}
                    style={{ width: "70px", height: "70px" }}
                  />
                  <Typography
                    variant="body1"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {tab.name}
                  </Typography>
                </Box>
              }
            />
          ))}
        </Tabs>
      </Box>

      {/* Tab Panels - Display grid of cards for each tab */}
      {menuItemCategories?.map((tab, index) => (
        <div
          key={`menuitemcategory-${tab.id}`}
          role="tabpanel"
          hidden={value !== index}
        >
          <Grid2 container p={2} spacing={{ xs: 2, md: 3 }} role="tabpanel">
            {tab.menu_items?.map((_, itemIndex) => (
              <Grid2
                key={`menuitem-${_.id}`}
                size={2}
                item="true"
                alignItems={"center"}
              >
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={
                        _.image_url
                          ? genImageUrl(_.image_url)
                          : "/assets/imgs/blank-image.svg"
                      }
                      alt={_.name}
                    />
                    <CardContent sx={{ pb: 0 }}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 600 }}
                        textTransform={"capitalize"}
                      >
                        {_.name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography variant="h4" color="success">
                        {formatCurrency(_.sale_price)}
                      </Typography>
                      <IconButton
                        aria-label="Thêm món"
                        size="small"
                        color="error"
                        onClick={() => addToCart(_)}
                      >
                        <AddIcon />
                      </IconButton>
                    </Stack>
                  </CardActions>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        </div>
      ))}
    </Box>
  );
};

export default MenuGroup;
