import React from "react";
import {
  Box,
  Drawer,
  Toolbar,
  Typography,
  Stack,
  Button,
  BottomNavigationAction,
  BottomNavigation,
  IconButton,
  Grid2,
  Container,
  Chip,
} from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import dayjs from "dayjs";
import Urls from "../../constants/urls";
import CustomIconButton from "./Partials/CustomIconButton";

const sidebarNav = 100;
const drawerWidth = 460;

const getCurrentDate = () => {
  const daysOfWeek = [
    "Chủ Nhật",
    "Thứ Hai",
    "Thứ Ba",
    "Thứ Tư",
    "Thứ Năm",
    "Thứ Sáu",
    "Thứ Bảy",
  ];
  const now = dayjs();
  return `${daysOfWeek[now.day()]}, ${now.date()}/${
    now.month() + 1
  }/${now.year()}`;
};

export default function Layout() {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const location = useLocation(); // Get the current location

  return (
    <Box sx={{ display: "flex" }}>
      {/* App Bar */}
      {/* <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            My App
          </Typography>
        </Toolbar>
      </AppBar> */}

      {/* Sidebar (first column) */}
      <Drawer
        variant="permanent"
        sx={{
          width: sidebarNav,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: sidebarNav,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Stack
          sx={{ overflow: "auto", height: "calc(100vh - 64px)" }}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <BottomNavigation
            showLabels
            sx={{ flexDirection: "column", height: "auto" }}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <CustomIconButton
              icon={TableRestaurantIcon}
              text="Bàn"
              url={Urls.home}
              isActive={location.pathname === Urls.home}
            />
            <CustomIconButton
              icon={TableRestaurantIcon}
              text="Đơn"
              url={Urls.order}
              isActive={location.pathname === Urls.order}
            />

            {/* <BottomNavigationAction
              sx={{
                py: 2,
              }}
              label="Bàn"
              icon={<TableRestaurantIcon />}
            />
            <BottomNavigationAction
              sx={{
                py: 2,
              }}
              label="Đơn"
              icon={<ReceiptIcon />}
            /> */}
          </BottomNavigation>
          <nav>
            {/* Sidebar content goes here */}
            <Stack direction={"column"}>
              <IconButton>
                <LogoutIcon />
                <Typography variant="body2">Thoát</Typography>
              </IconButton>
            </Stack>
          </nav>
        </Stack>
      </Drawer>

      {/* Main content (second column) */}
      <Box
        component="main"
        sx={{
          transition: "margin-left 0.3s",
          width: open ? "calc(100% - " + (drawerWidth + sidebarNav) + "px)" : "100%",
        }}
      >
        <Toolbar sx={{ backgroundColor: "white" }}>
          <Grid2
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            {/* Display Current Date */}
            <Grid2 item>
              <Chip
                variant="outline"
                label={getCurrentDate()}
                sx={{ backgroundColor: "primary.light", mr: 2 }}
              />
              <Link to={Urls.takeAwayOrder}>
                <Button
                  // size="small"
                  variant="contained"
                  startIcon={<AddShoppingCartIcon />}
                  sx={{
                    backgroundColor: "primary.light",
                    mr: 2,
                    color: "text.primary",
                  }}
                >
                  <Typography
                    component={"span"}
                    variant="body2"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Lên đơn hàng
                  </Typography>
                </Button>
              </Link>
            </Grid2>

            {/* Profile Box */}
            <Grid2 item>
              <Box display="flex" alignItems="center">
                <AccountCircleIcon sx={{ marginRight: 1 }} />
                <Box>
                  <Typography variant="body1">John Doe</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Software Engineer
                  </Typography>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
        </Toolbar>
        <Outlet />
        {/* Main content goes here */}
      </Box>

      {/* Hidable part (third column) */}
      <Drawer
        variant="persistent"
        anchor="right"
        open={false}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          {/* Hidable content */}
          <Typography>Extra Content</Typography>
          <Typography>Extra Info</Typography>
        </Box>
      </Drawer>
    </Box>
  );
}
