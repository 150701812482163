import dayjs from "dayjs";
import React from "react";
import { formatCurrency } from "../../../helpers/format";

const BillContent = ({ cartItems, totalAmount }) => {
  return (
    <div id="bill-content" className="bill">
      <div className="bill-header">
        {/* <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td
                style={{
                  width: "40%",
                  lineHeight: "normal",
                  textAlign: "center",
                  padding: "5px",
                }}
              >
                <h2 style={{ margin: 0 }}>Bình An</h2>
              </td>
              <td style={{ width: "60%", padding: "5px" }}>
                <p style={{ fontSize: "10px", margin: 0 }}>
                  Địa chỉ: 273 Đường Số 7, P. Bình Trị Đông B , Quận Bình Tân ,
                  TP. HCM
                </p>
                <p style={{ fontSize: "10px", margin: 0 }}>
                  Điện thoại: 090 712 32 32
                </p>
              </td>
            </tr>
          </tbody>
        </table> */}
        <div
          style={{
            lineHeight: "normal",
            textAlign: "center",
            padding: "5px",
          }}
        >
          <h2 style={{ margin: 0 }}>Bình An</h2>
        </div>
        <div style={{ fontSize: "8px", padding: "5px" }}>
          <p style={{ margin: 0 }}>
            Địa chỉ: 273 Đường Số 7, P. Bình Trị Đông B , Quận Bình Tân , TP.
            HCM
          </p>
          <p style={{ margin: 0 }}>
            Điện thoại: 090 712 32 32
          </p>
        </div>
      </div>

      <div className="bill-details">
        <table
          style={{ width: "100%", fontSize: "11px", marginBottom: "20px" }}
        >
          <tbody>
            {/* <tr>
              <td style={{ width: "40%" }}>Mã hóa đơn:</td>
              <td style={{ width: "60%" }}>In tạm</td>
            </tr>
            <tr>
              <td>Bàn:</td>
              <td>Mang đi</td>
            </tr> */}
            <tr>
              <td>In lúc:</td>
              <td>{dayjs(Date.now()).format("DD/MM/YYYY H:mm")}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="bill-summary">
        <table
          style={{
            fontSize: "12px",
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "40%", borderBottom: "1px solid black" }}>
                Mặt hàng
              </th>
              <th
                style={{
                  width: "10%",
                  textAlign: "right",
                  borderBottom: "1px solid black",
                }}
              >
                SL
              </th>
              <th
                style={{
                  width: "20%",
                  textAlign: "right",
                  borderBottom: "1px solid black",
                }}
              >
                Giá
              </th>
              <th
                style={{
                  width: "30%",
                  textAlign: "right",
                  borderBottom: "1px solid black",
                }}
              >
                T Tiền
              </th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((item, index) => (
              <tr key={index}>
                <td
                  style={{
                    borderBottom: "1px dashed black",
                    padding: "5px 3px",
                  }}
                >
                  {item.name}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    borderBottom: "1px dashed black",
                    padding: "5px 3px",
                  }}
                >
                  {item.quantity}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    borderBottom: "1px dashed black",
                    padding: "5px 3px",
                  }}
                >
                  {formatCurrency(item.sale_price)}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    borderBottom: "1px dashed black",
                    padding: "5px 3px",
                  }}
                >
                  {formatCurrency(item.sale_price * item.quantity)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="total">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr className="total-amount">
              <td
                style={{ width: "70%", fontSize: "16px", fontWeight: "bold" }}
              >
                Tổng tiền
              </td>
              <td
                style={{
                  width: "30%",
                  textAlign: "right",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {totalAmount}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        className="bill-footer"
        style={{ textAlign: "center", fontSize: "11px", fontStyle: "italic" }}
      >
        <p>Xin cảm ơn quý khách! Hẹn gặp lại!</p>
      </div>
    </div>
  );
};

export default BillContent;
