import React from "react";
import {
  Grid2,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
  useTheme,
} from "@mui/material";
import { useTableContext } from "../../../context/TableContext";
import { renderStatusChip } from "../../../helpers/ui";
import { getTables } from "../../../requests/tables";
import NoDataBox from "../../../components/layout/NoData";

export default function TableGrid() {
  const theme = useTheme();
  const { handleTableClick, tables } = useTableContext(); // Access the context

  if (!tables || (tables && tables.length === 0)) {
    return <NoDataBox />;
  }

  return (
    <Grid2 container spacing={2}>
      {tables
        ? tables.map((table) => (
            <Grid2 item xs={12} sm={6} md={3} key={table.id}>
              <Card
                onClick={() => handleTableClick(table)}
                sx={{ cursor: "pointer", position: "relative" }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={"/assets/imgs/blank-image.svg"}
                  alt={table.table_number}
                />
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" component="span">
                      {table.table_number}
                    </Typography>
                    {/* Render table status as a Chip with MUI theme colors */}
                    {renderStatusChip(table.status, theme, {
                      position: "absolute", // Make the chip position absolute
                      top: 8,
                      right: 8,
                    })}
                  </Box>
                </CardContent>
              </Card>
            </Grid2>
          ))
        : null}
    </Grid2>
  );
}
