import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import React from 'react';
import HomePage from './pages/Home/Home';
import Cart from './pages/Cart/Cart';
import Layout from './components/layout/Layout';
import OrderPage from './pages/Order/Order';
import Urls from './constants/urls';
import TakeAwayPage from './pages/TakeAway/TakeAway';
import AuthLayout from './components/layout/AuthenticationLayout';
import LoginForm from './pages/Login/Login';


let router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: Urls.home,
        element: <HomePage />,
      },
      {
        path: Urls.takeAwayOrder,
        element: <TakeAwayPage />,
      },
      {
        path: Urls.order,
        element: <OrderPage />,
      },
    ]
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "/auth" + Urls.login,
        element: <LoginForm />,
      },
    ]
  },

]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
