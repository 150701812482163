import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#FFC8AB',  // Primary 200
            main: '#FF8500',   // Primary 500
            dark: '#943700',   // Primary 800
            contrastText: '#FFF', // Text color on primary
        },
        error: {
            light: '#FEC7CB',  // Error 200
            main: '#FE3440',   // Error 500
            dark: '#82010C',   // Error 800
            contrastText: '#FFF', // Text color on error
        },
        warning: {
            light: '#FED873',  // Warning 200
            main: '#FEC634',   // Warning 500
            dark: '#97751B',   // Warning 700
            contrastText: '#FFF', // Text color on warning
        },
        success: {
            light: '#BDEACD',  // Success 200
            main: '#36C973',   // Success 500
            dark: '#1A6137',   // Success 900
            contrastText: '#FFF', // Text color on success
        },
        neutral: {
            light: '#F5F5F5',  // Neutral 100
            main: '#D4D4D4',   // Neutral 300
            dark: '#525252',   // Neutral 700
            contrastText: '#333333', // Text color on neutral
        },
        background: {
            default: '#FAFAFA',  // Neutral 50 for default background
            paper: '#FFF',       // White background for paper
        },
        text: {
            primary: '#252525',  // Black color for primary text
            secondary: '#737373',  // Neutral 500 for secondary text
        },
    },
    typography: {
        h1: {
            fontSize: '28px',
            fontWeight: 700,  // bold
            // letterSpacing: '-1px',
            lineHeight: 'auto',
        },
        h2: {
            fontSize: '24px',
            fontWeight: 700,  // bold
            // letterSpacing: '-1px',
            lineHeight: 'auto',
        },
        h3: {
            fontSize: '20px',
            fontWeight: 700,  // bold
            // letterSpacing: '-1px',
            lineHeight: 'auto',
        },
        h4: {
            fontSize: '18px',
            fontWeight: 600,  // semi-bold
            // letterSpacing: '-0.25px',
            lineHeight: 'auto',
        },
        h5: {
            fontSize: '16px',
            fontWeight: 600,  // semi-bold
            // letterSpacing: '-1px',
            lineHeight: 'auto',
        },
        h6: {
            fontSize: '12px',
            fontWeight: 700,  // bold
            // letterSpacing: '-0.5px',
            lineHeight: 'auto',
        },
        subheading1: {
            fontSize: '16px',
            fontWeight: 500,  // medium
            // letterSpacing: '-0.5px',
            lineHeight: 'auto',
        },
        subheading2: {
            fontSize: '14px',
            fontWeight: 600,  // semi-bold
            // letterSpacing: '-0.5px',
            lineHeight: 'auto',
        },
        subheading3: {
            fontSize: '12px',
            fontWeight: 600,  // semi-bold
            // letterSpacing: '-0.5px',
            lineHeight: 'auto',
        },
        caption1: {
            fontSize: '14px',
            fontWeight: 500,  // medium
            // letterSpacing: '-0.5px',
            lineHeight: 'auto',
        },
        caption3: {
            fontSize: '14px',
            fontWeight: 400,  // regular
            // letterSpacing: '-0.5px',
            lineHeight: 'auto',
        },
        caption4: {
            fontSize: '12px',
            fontWeight: 400,  // regular
            // letterSpacing: '0px',
            lineHeight: 'auto',
        },
        caption5: {
            fontSize: '10px',
            fontWeight: 400,  // regular
            // letterSpacing: '0px',
            lineHeight: 'auto',
        },
        button: {
            fontSize: '16px',
            fontWeight: 600,  // semi-bold
            // letterSpacing: '-0.5px',
            lineHeight: 'auto',
        },
        buttonMedium: {
            fontSize: '14px',
            fontWeight: 600,  // semi-bold
            // letterSpacing: '-0.5px',
            lineHeight: 'auto',
        },
        buttonSmall: {
            fontSize: '12px',
            fontWeight: 600,  // semi-bold
            // letterSpacing: '-0.25px',
            lineHeight: 'auto',
        },
    },
});

export default theme;
