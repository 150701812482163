import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const MENU_GROUP_URL = API_URL + "public/menu-item-categories";

const getMenuGroups = () => {
    return axios.get(MENU_GROUP_URL + "/getAll").then(res => res.data);
}



export { getMenuGroups }