import React from "react";
import { Box, Typography } from "@mui/material";
import { TableProvider } from "../../context/TableContext";

const OrderPage = () => {

  return (
    <TableProvider>
      <Box sx={{ p: 4 }}>
        {/* Section Title */}
        <Typography variant="h4" component="p">
          Món ăn
        </Typography>
        <Typography component="p">
          Món ăn
        </Typography>
      </Box>
    </TableProvider>
  );
};

export default OrderPage;
