import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const RESERVATION_URL = API_URL + "public/reservations";

const createReservation = (table_id) => {
    return axios.get(RESERVATION_URL + "/createReservation/" + table_id).then(res => { return res.data });
}

const destroyReservation = (table_id) => {
    return axios.get(RESERVATION_URL + "/destroyReservation/" + table_id).then(res => { return res.data });
}



export { createReservation, destroyReservation }