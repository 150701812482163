import React, { createContext, useContext, useState } from "react";
import { getTables } from "../requests/tables";

// Create a TableContext to manage selected table and drawer visibility
const TableContext = createContext();

export const useTableContext = () => useContext(TableContext);

export const TableProvider = ({ children }) => {
  const [selectedTable, setSelectedTable] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(true);

  const [tables, setTables] = React.useState();
  const refetch = () => {
    getTables().then((data) => setTables([...data]));
  };

  React.useEffect(() => {
    refetch();
  }, []);

  const handleTableClick = (table) => {
    setSelectedTable(table);
    setDrawerOpen(true);
  };

  const closeDrawer = () => setDrawerOpen(false);

  React.useEffect(() => {
    if (selectedTable && tables) {
      const newSelectedTable = tables.filter(
        (table) => table.id === selectedTable.id
      );
      if (newSelectedTable?.length > 0) {
        setSelectedTable({ ...newSelectedTable[0] });
      }
    }
  }, [JSON.stringify(tables)]);

  return (
    <TableContext.Provider
      value={{
        tables,
        selectedTable,
        drawerOpen,
        handleTableClick,
        closeDrawer,
        refetch,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
