import dayjs from "dayjs";

const PrintQRCode = ({ table_number, qr_code_url }) => {
  return (
    <div id="print-content" className="print">
      <div className="print-header">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td
                style={{
                  width: "40%",
                  lineHeight: "normal",
                  textAlign: "center",
                  padding: "5px",
                }}
              >
                <h2 style={{ margin: 0 }}>Bình An</h2>
              </td>
              <td style={{ width: "60%", padding: "5px" }}>
                <p style={{ fontSize: "10px", margin: 0 }}>
                  Địa chỉ: 273 Đường Số 7, P. Bình Trị Đông B , Quận Bình Tân ,
                  TP. HCM
                </p>
                <p style={{ fontSize: "10px", margin: 0 }}>
                  Điện thoại: 090 712 32 32
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="print-details">
        <table style={{ width: "100%", fontSize: "11px" }}>
          <tbody>
            <tr>
              <td>In lúc:</td>
              <td>{dayjs(Date.now()).format("DD/MM/YYYY H:mm")}</td>
            </tr>
            <tr>
              <td>Bàn:</td>
              <td>{table_number}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="print-summary" style={{textAlign: 'center'}}>
        <img src={qr_code_url} alt="" />
      </div>

      <div
        className="print-footer"
        style={{ textAlign: "center", fontSize: "11px", fontStyle: "italic" }}
      >
        <p>Cảm ơn Quý khách đã chọn Bình An!</p>
        <p>Chúc Quý khách ngon miệng!</p>
      </div>
    </div>
  );
};

export default PrintQRCode;
