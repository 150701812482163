const TableStatus = {
    'available': {
        value: "available",
        label: "Còn trống",
    },

    'occupied': {
        value: "occupied",
        label: "Đang phục vụ",
    },

    'reserved': {
        value: "reserved",
        label: "Có khách",
    },

    'unavailable': {
        value: "unavailable",
        label: "Ngưng sử dụng",
    },
}


export default TableStatus;