import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const TABLE_URL = API_URL + "public/tables/getAll";

const getTables = () => {
    return axios.get(TABLE_URL).then(res => res.data);
}



export {getTables}