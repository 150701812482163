import React from "react";
import {
  Drawer,
  Box,
  Typography,
  Button,
  useTheme,
  Stack,
  List,
  ListItem,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TableBody,
} from "@mui/material";
import layoutSize from "../../../constants/layout";
import { useCart } from "../../../context/CartContext";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Link } from "react-router-dom";
import Urls from "../../../constants/urls";
import { formatCurrency } from "../../../helpers/format";
import { createOrders } from "../../../requests/orders";
import BillContent from "./PrintBill";

const TakeAwayDrawer = () => {
  const theme = useTheme();

  const {
    cartItems,
    increaseQuantity,
    decreaseQuantity,
    getTotalQuantity,
    getTotalAmount,
    clearCart,
  } = useCart();

  const printTempBill = () => {
    const printContent = document.getElementById("bill-content");

    if (!printContent) return;
    const printWindow = window.open("", "", "height=500,width=900px");
    printWindow.document.write("<html><head><title>In Hóa Đơn</title>");
    printWindow.document.write(
      "<style>@media print { body { font-family: Helvetica, sans-serif; }</style>"
    );
    printWindow.document.write("</head><body>");
    printWindow.document.write(printContent.outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const handlePayment = () => {
    createOrders(null, cartItems).then((res) => console.log(res));
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={true}
      PaperProps={{
        sx: {
          width: layoutSize.rightDrawer,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Typography
        variant="body1"
        fontWeight={700}
        pt={2}
        px={2}
        sx={{ textTransform: "capitalize" }}
      >
        Đơn hiện tại
      </Typography>
      <Typography
        variant="h6"
        px={2}
        sx={{ textTransform: "capitalize" }}
        color="primary"
      >
        {getTotalQuantity()} đơn vị
      </Typography>
      {cartItems.length === 0 ? (
        <Box
          display={"flex"}
          p={2}
          alignItems={"center"}
          justifyContent="center"
          sx={{ height: "calc(100vh - 56px)", width: "100%" }}
        >
          <Typography variant="h6" color="textSecondary">
            Chưa có đơn
          </Typography>
        </Box>
      ) : (
        <Box p={2} sx={{ height: "calc(100vh - 56px)", width: "100%" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }} padding="none">
                    Mặt hàng
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 600 }}
                    padding="none"
                    align="center"
                  >
                    SL
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 600 }}
                    padding="none"
                    align="right"
                  >
                    Đơn giá
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 600 }}
                    padding="none"
                    align="right"
                  >
                    T Tiền
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartItems.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell padding="none" component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell padding="none" align="right">
                      <Stack
                        direction={"row"}
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <IconButton
                          variant="contained"
                          color="info"
                          size="small"
                          onClick={() => decreaseQuantity(item.id)}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography variant="h6">{item.quantity}</Typography>
                        <IconButton
                          variant="contained"
                          color="info"
                          size="small"
                          onClick={() => increaseQuantity(item.id)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                    <TableCell padding="none" align="right">
                      {formatCurrency(item.sale_price)}
                    </TableCell>
                    <TableCell padding="none" align="right">
                      {formatCurrency(item.sale_price * item.quantity)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h3">Tổng tiền</Typography>
            <Typography variant="h3">
              {formatCurrency(getTotalAmount())}
            </Typography>
          </Box>
        </Box>
      )}

      <Box display="none" flexDirection={"column"} alignItems="center" my={2} p={2}>
        <BillContent cartItems={cartItems} totalAmount={formatCurrency(getTotalAmount())} />
      </Box>

      <Box
        sx={{
          padding: 2,
          borderTop: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.paper,
          position: "sticky",
          bottom: 0,
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-around"}
        >
          <Button variant="contained" color="primary" onClick={printTempBill}>
            In Bill tạm
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={handlePayment}
            disabled={getTotalQuantity() <= 0}
          >
            Thanh toán
          </Button>

          <Button variant="contained" color="error" onClick={clearCart}>
            Hủy đơn
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default TakeAwayDrawer;
