import React from "react";
import {
  Drawer,
  Box,
  Typography,
  Button,
  useTheme,
  Stack,
} from "@mui/material";
import { useTableContext } from "../../../context/TableContext";
import { renderStatusChip } from "../../../helpers/ui";
import TableStatus from "../../../constants/table_status";
import {
  createReservation,
  destroyReservation,
} from "../../../requests/reservations";
import { genImageUrl } from "../../../helpers/url";
import PrintQRCode from "./PrintQRCode";
import layoutSize from "../../../constants/layout";

const TableDrawer = () => {
  const { tables, selectedTable, drawerOpen, closeDrawer, refetch } =
    useTableContext(); // Access the context
  const [qrCode, setQrCode] = React.useState();
  const theme = useTheme();

  const getQRCode = async () => {
    if (selectedTable?.id)
      createReservation(selectedTable.id).then((res) => {
        if (res?.qr_code_url) {
          refetch();
        } else {
          console.log("Không có Qr Code");
        }
      });
  };

  React.useEffect(() => {
    if (selectedTable && selectedTable.reservation?.qr_code) {
      setQrCode(genImageUrl(selectedTable.reservation?.qr_code));
    } else {
      setQrCode(null);
    }
  }, [JSON.stringify(selectedTable)]);

  const printQrCode = () => {
    const printContent = document.getElementById("print-content");

    if (!printContent) return;
    const printWindow = window.open("", "", "height=500,width=900px");
    printWindow.document.write("<html><head><title>In Qr Code</title>");
    printWindow.document.write(
      "<style>@media print { body { font-family: Helvetica, sans-serif; }</style>"
    );
    printWindow.document.write("</head><body>");
    printWindow.document.write(printContent.outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const cancelReservation = () => {
    destroyReservation(selectedTable.id).then((res) => {
      refetch();
    });
  };

  const renderActionButtons = (status) => {
    switch (status) {
      case TableStatus.available.value:
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <Button variant="contained" color="primary" onClick={getQRCode}>
              QR Code
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: theme.palette.warning.main }}
            >
              Ngưng sử dụng
            </Button>
          </Box>
        );
      case TableStatus.occupied.value:
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <Button variant="contained" color="primary" onClick={printQrCode}>
              In QR Code
            </Button>
            <Button variant="contained" color="primary">
              Xem đơn
            </Button>
            <Button variant="contained" color="primary">
              Thanh toán
            </Button>
          </Box>
        );
      case TableStatus.reserved.value:
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <Button variant="contained" color="primary" onClick={printQrCode}>
              In QR Code
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: theme.palette.error.main }}
              onClick={cancelReservation}
            >
              Hủy đặt bàn
            </Button>
          </Box>
        );
      default:
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <Button variant="contained" color="primary">
              Sử dụng
            </Button>
          </Box>
        );
    }
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={drawerOpen}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          width: layoutSize.rightDrawer,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {selectedTable && (
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {/* Top Scrollable Content */}
          <Box sx={{ flex: 1, overflowY: "auto", padding: 2 }}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Typography variant="h2">{selectedTable.table_number}</Typography>
              <span>-</span>
              <Typography component={"div"} variant="body2">
                {renderStatusChip(selectedTable.status, theme)}
              </Typography>
            </Stack>
            {/* Additional Information */}
            {qrCode ? (
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems="center"
                my={2}
              >
                {/* <Typography variant="body2">QR Code đặt món</Typography>
                <img
                  src={genImageUrl(selectedTable.reservation.qr_code)} // QR Code image source
                  alt="QR Code"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                /> */}
                <PrintQRCode
                  table_number={selectedTable.table_number}
                  qr_code_url={qrCode}
                />
              </Box>
            ) : null}
          </Box>

          {/* Bottom Sticky Action Buttons */}
          <Box
            sx={{
              padding: 2,
              borderTop: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.paper,
              position: "sticky",
              bottom: 0,
            }}
          >
            {renderActionButtons(selectedTable.status)}
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export default TableDrawer;
