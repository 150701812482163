import { Chip } from "@mui/material";
import TableStatus from "../constants/table_status";

// Helper function to render the status with MUI theme colors
const renderStatusChip = (status, theme, sx = {}) => {
    let color;
    switch (status) {
        case "available":
            color = theme.palette.success.main; // MUI success color for Available
            break;
        case "occupied":
            color = theme.palette.error.main; // MUI error color for Occupied
            break;
        case "reserved":
            color = theme.palette.warning.main; // MUI warning color for Reserved
            break;
        default:
            color = theme.palette.text.secondary; // Default to secondary text color
    }

    return (
        <Chip
            label={TableStatus[status]?.label || 'n/a'}
            size="small"
            sx={{ backgroundColor: color, color: theme.palette.common.white, ...sx }} // Using white text color for contrast
        />
    );
};

export { renderStatusChip }