import React from "react";
import { Box, Typography } from "@mui/material";
import TableGrid from "./Partials/TableGrid";
import TableDrawer from "./Partials/TableDrawer";
import { TableProvider } from "../../context/TableContext";

const HomePage = () => {

  return (
    <TableProvider>
      <Box sx={{ p: 4 }}>
        {/* Section Title */}
        <Typography variant="h4" component="h2">
          Bàn
        </Typography>
        <TableGrid />
        <TableDrawer />
      </Box>
    </TableProvider>
  );
};

export default HomePage;
